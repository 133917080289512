import React from "react"
import Link from "gatsby-link"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/SEO"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <SEO title={post.frontmatter.title} description={post.frontmatter.author} />
      <div className="header">
        <h1>{post.frontmatter.title}</h1>
        <h5>{post.frontmatter.description}</h5>
      </div>
      <div className="zzproduct-content">
        <section className="container grid-xl">
          <div className="columns">
            <div className="column col-9 col-sm-12">
              <div style={{ paddingLeft: "0.7em", paddingRight: "0.7em", paddingBottom: "0.9em" }} dangerouslySetInnerHTML={{ __html: post.html }}></div>
            </div>
            <div className="column col-3 col-sm-12">
              {post.frontmatter.headlines.map(headline => (
                <div className="headline-tile">
                  <div className="headline-icon">
                    <FontAwesomeIcon icon={['fas', headline.headline_icon]} size="2x" />
                  </div>
                  <div className="headline-content">{headline.headline_title}
                    <div className="headline-action">
                      <Link to={headline.headline_link}>{headline.headline_link_text}</Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <div className="filler">&nbsp;</div>
        {post.frontmatter.sections.map(section => (
          <section className="section product-details small alt">
            <div className={section.section_name}></div>
            <div className="container grid-xl">
              <div className="frame-box">

                <div className="columns">
                  <div className="column col-3 col-sm-12 colhdr">
                    <h3>{section.section_name}</h3>
                    <p>{section.section_description}</p>
                  </div>

                  <div className="column col-9 col-sm-12">
                    <div className="columns col-12 col-sm-12">
                      {section.features.map(feature => (
                        <div className="column col-4 col-md-6 col-sm-12">
                          <div className="feature-icon">
                            <FontAwesomeIcon icon={['fas', feature.feature_icon]} size="2x" />
                          </div>
                          <h55>{feature.feature_title}</h55>
                          <div className="feature-content">
                            <p>{feature.feature_description}
                              {feature.feature_link !== null &&
                                <span style={{ fontSize: "9pt" }}>
                                  <Link to={feature.feature_link}>{feature.feature_link_text}</Link>
                                </span>}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ))}
      </div>
      <div className="product-content">
        {'related' in post.frontmatter &&
          <section>

              <section className="section product-details small ">
                <div className="container grid-xl">
                  <div className="frame-box">

                    <div className="columns">
                      <div className="column  col-3 col-sm-12 colhdr">
                        <h3>Related products</h3>
                      </div>
                      <div className="column col-9 col-sm-12">
                        <div className="columns col-12 col-sm-12">
                        {post.frontmatter.related.map(relate => (
                        <div className="column col-12">
                        <div className="related-tile">
                            <div className="related-icon"><FontAwesomeIcon icon={['fas', relate.related_icon]} size="2x" /></div>
                            <div className="related-content">{relate.related_description}

                              {relate.related_link !== null &&
                                <span>
                                  <br /><Link to={relate.related_link}>{relate.related_link_text}</Link>
                                </span>}

                            </div>
                          </div>
                        </div>
                          ))}
                          </div>
                          </div>
                  </div>
                  </div>
                  </div>
              </section>
          </section>
        }
      </div>
    </Layout>
  )
}

export const query = graphql`
query($slug: String!) {
        markdownRemark(fields: {slug: {eq: $slug }}, frontmatter: {template: {eq: "product" }}) {
        html
        frontmatter {
          template
          title
          description
          division
          suite
          date
          headlines {
            headline_icon
            headline_title
            headline_description
            headline_link_text
            headline_link
          }
          sections {
            section_name
            section_description
            section_link
            features {
              feature_description
              feature_icon
              feature_link
              feature_link_text
              feature_title
             }
           }
          related {
            related_title
            related_icon
            related_description
            related_link        
            related_link_text    
          }
        }
    }
  }
`